import Sanity from 'lib/SanityClient';

import {
  LanguagesSchema,
  RawLanguagesSchema,
} from 'lib/SanityClient/schemas/languages';
import validateSchema from 'utils/validateSchema';
import type {
  Languages,
  RawLanguages,
} from 'lib/SanityClient/schemas/languages';

type GetPaginationIndiciesOptions = {
  pageNumber: number;
  limit: number;
};
export function getPaginationIndicies(options: GetPaginationIndiciesOptions) {
  const start = options.limit * (options.pageNumber - 1);
  const end = start + (options.limit - 1);
  return { start, end };
}

export async function getLanguages(query: string) {
  const response: RawLanguages = (await Sanity.fetch(query)) as RawLanguages;
  const validatedResponse: RawLanguages = validateSchema({
    schema: RawLanguagesSchema,
    data: response,
    defaultValue: [{ language: 'en' }],
  });
  const uniqueLanguages: Languages = Array.from(
    new Set(validatedResponse.map((r: { language: string }) => r.language)),
  ).map((language) => language);
  const validatedLanguages: Languages = validateSchema({
    schema: LanguagesSchema,
    data: uniqueLanguages,
    defaultValue: ['en'],
  });
  return validatedLanguages;
}
