import { z } from 'zod';

export const RawLanguagesSchema = z.array(
  z.object({
    language: z.string(),
  }),
);
export const LanguagesSchema = z.array(z.string());

export type Languages = z.infer<typeof LanguagesSchema>;
export type RawLanguages = z.infer<typeof RawLanguagesSchema>;
