import memoize from 'lodash/memoize';
import get from 'lodash/get';

import sanitizeImage from 'state/sanitizers/sanitizeImage';
import sanitizeArticleLinks from 'state/sanitizers/sanitizeArticleLinks';

import { EmphasizeModuleColorMap } from 'constants/ColorScheme';

import { IBrowsePage } from 'types';
import sanitizeDrugLinks from './sanitizeDrugLinks';

export default memoize(
  (browsePage: unknown, articlesOnTopic: any[]): IBrowsePage | null => {
    const id = get(browsePage, '_id');

    if (!id) {
      return null;
    }

    return {
      id,
      documentType: get(browsePage, '_type'),
      type: get(browsePage, 'type'),
      slug: get(browsePage, 'slug'),
      title: get(browsePage, 'title'),
      hero: {
        image: sanitizeImage(get(browsePage, 'heroDesktopImage')),
        color:
          EmphasizeModuleColorMap[get(browsePage, 'heroColor', '#ffffff')] ||
          'WHITE',
        imageBackgroundColor: get(
          browsePage,
          'heroImageBackgroundColor',
          '#ffffff',
        ),
        title: get(browsePage, 'heroTitle', ''),
        description: get(browsePage, 'heroDescription', ''),
      },
      articles: [
        ...sanitizeArticleLinks(
          articlesOnTopic.filter((a) => a?._type !== 'drug'),
        ),
        ...sanitizeDrugLinks(
          articlesOnTopic.filter((a) => a?._type === 'drug'),
        ),
      ],
      seo: {
        title: get(browsePage, 'metaTitle', ''),
        description: get(browsePage, 'metaDescription', ''),
        image: sanitizeImage(get(browsePage, 'metaImage')),
        structuredData: get(browsePage, 'structuredData.code', null),
        canonicalUrl: get(browsePage, 'canonicalUrl', ''),
      },
    };
  },
);
